import { _post, _get } from "../http";

export default {
  login: (data) => _post("admin/login/sysLogin", data),
  userList: (data) => _get("admin/user/list", data), // 用户信息列表

  saveUser: (data) => _post("admin/user/save", data),
  updateById: (data) => _post("admin/user/updateById", data),
  removeUser: (data) => _post("admin/user/remove", data),
  saveUserRole: (data) => _post("admin/user/saveUserRole", data),
};
