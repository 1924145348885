import Vue from "vue";
import VueRouter from "vue-router";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import Cookies from "js-cookie";
import store from "../store";
import { systemChildren } from "./system";
import { businessChildren } from "./business";
import { moduleChildren } from "./module";
Vue.use(VueRouter);

const layoutChildren = [
  {
    path: "/index",
    name: "index",
    component: () => import("@/views/home/Index.vue"),
    meta: {
      title: "首页",
    },
    children: [],
  },
  {
    path: "/system",
    name: "system",
    component: () => import("@/views/system/Index.vue"),
    meta: {
      title: "系统管理",
    },
    children: systemChildren,
  },

  {
    path: "/business",
    name: "business",
    component: () => import("@/views/business/Index.vue"),
    meta: {
      title: "业务管理",
    },
    children: businessChildren,
  },
  {
    path: "/module",
    name: "module",
    redirect: "/module/healthIQ",
    component: () => import("@/views/module/index.vue"),
    meta: {
      title: "模块管理",
    },
    children: moduleChildren,
  },
];
const routes = [
  {
    path: "/",
    redirect: "/index",
  },

  {
    path: "/index",
    name: "index",
    component: () => import("../views/layout/Index.vue"),
    meta: {
      title: "首页",
    },
    children: layoutChildren,
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/login/Index.vue"),
  },
  { path: "*", redirect: "/404", hidden: true },
  {
    path: "/404",
    component: () => import("@/views/error-page/404"),
    name: "page404",
    meta: { title: "page404", noCache: true },
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  NProgress.start();
  let isLogin = Cookies.get("isLogin");
  if (to.name !== "login" && !isLogin) return next("/login");
  window.sessionStorage.setItem("activePath", to.path);
  store.dispatch("setHomeTagsList", to);
  next();
});
router.afterEach(() => {
  NProgress.done();
});
export default router;
