export const businessChildren = [
  {
    path: "company",
    name: "company",
    component: () => import("@/views/business/company/Index.vue"),
    meta: {
      title: "客户公司管理",
    },
    children: [
      {
        path: "companyList",
        name: "companyList",
        component: () =>
          import("@/views/business/company/companyList/Index.vue"),
        meta: {
          title: "客户公司列表",
        },
        children: [],
      },
    ],
  },
  {
    path: "order",
    name: "order",
    component: () => import("@/views/business/order/Index.vue"),
    meta: {
      title: "订单管理",
    },
    children: [
      {
        path: "orderList",
        name: "orderList",

        component: () => import("@/views/business/order/orderList/Index.vue"),
        meta: {
          title: "订单列表",
        },
      },
      {
        path: "companyOrder",
        name: "companyOrder",
        redirect: "companyOrder/index",
        component: () =>
          import("@/views/business/order/companyOrder/Index.vue"),
        meta: {
          title: "客户公司列表",
        },
        children: [
          {
            path: "index",
            name: "companyOrder",
            component: () =>
              import("@/views/business/order/companyOrder/common/Index.vue"),
            meta: {
              title: "公司订单",
            },
          },
          {
            path: "Topic",
            name: "topic",
            component: () =>
              import("@/views/business/order/companyOrder/common/Topic.vue"),
            meta: {
              title: "公司订单选题",
            },
          },
          {
            path: "getTopic",
            name: "getTopic",
            component: () =>
              import("@/views/business/order/companyOrder/common/getTopic.vue"),
            meta: {
              title: "公司订单选题",
            },
          },
        ],
      },
    ],
  },
];
