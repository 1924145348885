import axios from "axios"; // 引入axios
// import QS from 'qs'; // 引入qs模块，用来序列化post类型的数据
// import router from '../router';
// import store from '../store/index';

const baseURL = {
  production: "https://ehbusmag.h2hlife.com/busiweb/",
  development: "busiweb/",
};

axios.defaults.baseURL = baseURL[process.env.NODE_ENV];

axios.defaults.withCredentials = true;

axios.interceptors.response.use(
  (response) => {
    if (response.status === 200) {
      return Promise.resolve(response);
    } else {
      return Promise.reject(response);
    }
  },
  (error) => {
    if (error.response.status) {
      return Promise.reject(error.response);
    }
  }
);

export function _get(url, params) {
  return new Promise((resolve, reject) => {
    axios.defaults.headers["Content-Type"] = "application/json";
    axios
      .get(url, {
        // params: QS.stringify(params, { arrayFormat: 'repeat' }),
        params: params,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.data);
      });
  });
}

export function _post(url, params) {
  return new Promise((resolve, reject) => {
    axios.defaults.headers["Content-Type"] = "application/json";
    axios
      .post(url, JSON.stringify(params))
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.data);
      });
  });
}
export function _put(url, params) {
  return new Promise((resolve, reject) => {
    axios.defaults.headers["Content-Type"] = "application/json";
    axios
      .put(url, JSON.stringify(params))
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.data);
      });
  });
}
export function _delete(url, params) {
  return new Promise((resolve, reject) => {
    axios.defaults.headers["Content-Type"] = "application/json";
    axios
      .delete(url, {
        data: params,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.data);
      });
  });
}

// POST 方法封装  (文件上传)
export const uploadFileRequest = (url, params) => {
  return new Promise((resolve, reject) => {
    axios.defaults.headers["Content-Type"] = "multipart/form-data";
    axios
      .post(url, JSON.stringify(params))
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.data);
      });
  });
};
export const exportFileRequest = (url, params) => {
  return new Promise((resolve, reject) => {
    axios({
      url,
      method: "post",
      data: JSON.stringify(params),
      responseType: "blob",
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.data);
      });
  });
};
