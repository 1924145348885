import { _get, _post, _delete } from "../http";

export default {
  getRoleList: (data) => _get("admin/role/roleList", data), //角色表查询
  addRoleList: (data) => _post("admin/role/add", data),
  updateRole: (data) => _post("admin/role/updateRole", data),
  permissionList: (data) => _get("admin/menu/permissionList", data),
  saveRoleMenu: (data) => _post("admin/role/saveRoleMenu", data), //更新角色关联权限关系
  removeRole: (data) => _delete("admin/role/remove", data),
};
