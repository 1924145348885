import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/css/global.css";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import api from "@/request/api.js";
import * as echarts from "echarts";
Vue.prototype.$echarts = echarts;
import {
  showFullScreenLoading,
  hideFullScreenLoading,
} from "./utils/common/loading";

Vue.config.productionTip = false;
Vue.config.silent = true;
Vue.prototype.$API = api;
Vue.prototype.$showLoading = showFullScreenLoading;
Vue.prototype.$hideLoading = hideFullScreenLoading;
Vue.use(ElementUI);
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
