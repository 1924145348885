// 导入用户信息相关接口

const user = {
  state: {
    userInfo: {},
  },
  getters: {
    userInfo: (state) => state.userInfo,
  },
  mutations: {
    SET_USER(state, data) {
      state.userInfo = data;
    },
  },
  actions: {
    setUserInfo({ commit }, data) {
      commit("SET_USER", data);
    },
    clearUserInfo({ commit }) {
      commit("SET_USER", {});
    },
  },
};

export default user;
