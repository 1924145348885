import { _post, _delete, _get } from "../http";

export default {
  sCompanyQuery: (data) => _get("/base_service/company/sCompanyQuery", data), //healthIQ题目查
  sCompanySave: (data) => _post("base_service/company/sCompanySave", data), //healthIQ题目查
  sCompanyRemove: (data) =>
    _delete("base_service/company/sCompanyRemove", data), //healthIQ题目

  sassOrderQuery: (data) =>
    _get("/base_service/sassOrder/sassOrderQuery", data), //healthIQ题目查

  sassOrderSave: (data) => _post("base_service/sassOrder/sassOrderSave", data), //healthIQ题目查
  sassOrderRemove: (data) =>
    _delete("base_service/sassOrder/sassOrderRemove", data), //healthIQ题目

  sassOrderHealthIQInfo: (data) =>
    _get("/base_service/sassOrder/sassOrderHealthIQInfo", data),
};
