// healthIQ/question/findHealthIQByCondition
// healthIQ题目查询

import { _post, _delete, exportFileRequest } from "../http";

export default {
  findHealthIQ: (data) =>
    _post("healthIQ/question/findHealthIQByCondition", data), //healthIQ题目查
  saveHealthIQ: (data) => _post("healthIQ/question/saveHealthIQ", data), //healthIQ题目查
  removeHealthIQ: (data) => _delete("healthIQ/question/removeHealthIQ", data), //healthIQ题目
  healthExcelExportFile: (data) =>
    exportFileRequest("healthIQ/Excel/healthExcelExportFile", data), //healthIQ题目
  sassOrderHealthIQBaseVChoice: (data) =>
    _post("healthIQ/sassOrder/sassOrderHealthIQBaseVChoice", data),
  orderAllHealthIQ: (data) =>
    _post("healthIQ/sassOrder/allHealthiqPageInit", data), //选题题库页面初始化
  orderSelectHealthIQ: (data) =>
    _post("healthIQ/sassOrder/sassOrderHealthiqPageInit", data), //healthIQ已经选中题列表页面初始化
  sassOrderHealthExcelExportFile: (data) =>
    exportFileRequest("healthIQ/Excel/sassOrderHealthExcelExportFile", data),
};
